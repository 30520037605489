var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center font-proximaNormal rounded-md cursor-pointer",class:[
    _vm.width,
    _vm.background,
    _vm.paddingX,
    _vm.paddingY,
    _vm.marginT,
    _vm.fontSize,
    _vm.textColor,
  ]},[_vm._v(" "+_vm._s(_vm.title)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }