<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 max-h-screen overflow-y-auto flex flex-col items-center"
        >
          <!-- justify-center -->
          <div class="w-10/12 modal-body mt-5 mb-0">
            <!-- <h3 class="text-base">{{ $t("delete_account_question") }}</h3> -->
            <div class="text-base font-proximaMedium">
              <slot name="actionQuestion"> Action Question ? </slot>
            </div>
            <!-- <h5 class="mt-2 text-xs font-proximaLight">
              {{ $t("delete_account_text") }}
            </h5> -->
            <div class="mt-2 text-xs font-proximaLight">
              <slot name="actionText"> Action text </slot>
            </div>
          </div>

          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="leftBtn">
                <BorderedButton
                  class="md:mr-1"
                  :title="$t('global_cancel')"
                  borderColor="border-mediumGrey"
                  textCl="text-darkGrey"
                  paddingX="px-8"
                  @click.native="$emit('close')"
                />
              </slot>
            </div>
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="rightBtn">
                <SubmitButton
                  class="md:ml-1"
                  :title="$t('global_confirm')"
                  background="bg-success"
                  paddingX="px-8"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

export default {
  props: {
    show: Boolean,
  },
  components: {
    SubmitButton,
    BorderedButton,
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  //position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>