<template>
  <div
    class="text-center font-proximaNormal rounded-md cursor-pointer"
    :class="[
      width,
      background,
      paddingX,
      paddingY,
      marginT,
      fontSize,
      textColor,
    ]"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String],
      default: "w-full",
    },
    paddingX: {
      type: [String],
      default: "px-20",
    },
    paddingY: {
      type: [String],
      default: "py-3",
    },
    fontSize: {
      type: [String],
      default: "text-sm",
    },
    marginT: {
      type: [String],
      default: "mt-8",
    },
    title: {
      type: [String],
      default: "this is title",
    },
    background: {
      type: [String],
      default: "bg-primary",
    },
    textColor: {
      type: [String],
      default: "text-white",
    },
  },
};
</script>