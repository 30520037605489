<template>
  <p class="" v-if="errors">
    <span
      v-for="(error, i) in errors"
      :key="i"
      class="text-xs text-red font-proximaLight"
      >{{ error }}</span
    >
  </p>
</template>

<script>
export default {
    props: [
        "errors"
    ]
};
</script>