<template>
  <div
    class="
      py-3
      text-white text-center text-sm
      font-proximaNormal
      bg-white
      rounded-md
      border
      cursor-pointer
    "
    :class="[width, textCl, borderColor, paddingX, marginT]"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String],
      default: "w-full",
    },
    paddingX: {
      type: [String],
      default: "px-20",
    },
    marginT: {
      type: [String],
      default: "mt-8",
    },
    title: {
      type: [String],
      default: "this is title",
    },
    textCl: {
      type: [String],
      default: "text-mediumGrey",
    },
    borderColor: {
      type: [String],
      default: "border-mediumGrey",
    }
  }
};
</script>